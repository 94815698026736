import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen66 } from '../../../components/images/support/subscriptions/Screen66.generated';
import { Screen67 } from '../../../components/images/support/subscriptions/Screen67.generated';
import { Screen68 } from '../../../components/images/support/subscriptions/Screen68.generated';
import { Screen69 } from '../../../components/images/support/subscriptions/Screen69.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invoices"
    }}>{`Invoices`}</h1>
    <p>{`Invoice details can be found inside the `}<strong parentName="p">{`Settings`}</strong>{` page`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Settings`}</strong>{` menu and click the `}<strong parentName="li">{`Settings`}</strong>{` button in the `}<strong parentName="li">{`Billing`}</strong>{` section.`}</li>
    </ol>

    <Screen66 mdxType="Screen66" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Invoices`}</strong>{` tab.`}</li>
    </ol>

    <Screen67 mdxType="Screen67" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the invoice you want to download.`}</li>
    </ol>

    <Screen68 mdxType="Screen68" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`View or download`}</strong>{` button.`}</li>
    </ol>

    <Screen69 mdxType="Screen69" />
    <h1 {...{
      "id": "great-work-all-done-"
    }}>{`Great work, all done 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      